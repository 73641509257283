import { APIHost } from './../modules/common/constants';

enum APIService {
  auth,
  protected,
  public,
}

function getBaseUrl(service: APIService) {
if (service === APIService.protected) {
    return `${APIHost}/protected`;
  } else if (service === APIService.public) {
    return `${APIHost}/public`;
  }
  return '';
}

export const API_PATHS = {
  operatingStats: `${getBaseUrl(APIService.public)}/stats/get`,
};
