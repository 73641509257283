import { alpha, createTheme, darken } from "@mui/material";
import { BLACK_TEXT, GREY_300, PRIMARY, SECONDARY } from "./colors";

export const MUI_THEME = createTheme( {
  palette: {
    primary: {
      light: alpha(PRIMARY, 0.9),
      main: PRIMARY,
      dark: darken(PRIMARY, 0.1),
      contrastText: "#ffffff",
    },
    secondary: {
      light: alpha(SECONDARY, 0.9),
      main: SECONDARY,
      dark: darken(SECONDARY, 0.1),
      contrastText: "#ffffff",
    },
    text: {
      primary: BLACK_TEXT,
      secondary: alpha(BLACK_TEXT, 0.34),
    },
  },
  typography: {
    htmlFontSize: 14,
    fontSize: 14,
    h1: { fontSize: 96, lineHeight: "112px", fontWeight: 700 },
    h2: { fontSize: 60, lineHeight: "72px", fontWeight: 700 },
    h3: { fontSize: 48, lineHeight: "50px", fontWeight: 700 },
    h4: { fontSize: 34, lineHeight: "38px", fontWeight: 700 },
    h5: { fontSize: 24, lineHeight: "30px", fontWeight: 700 },
    h6: { fontSize: 20, lineHeight: "26px", fontWeight: 700 },
    subtitle1: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: 700,
    },
    subtitle2: {
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 700,
    },
    body1: {
      fontSize: "16px",
      lineHeight: "24px",
    },
    body2: {
      fontSize: "14px",
      lineHeight: "24px",
    },
    caption: {
      fontSize: "12px",
      lineHeight: "14px",
    },
    button: {
      fontSize: "16px",
      textTransform: "none",
      fontWeight: "normal",
      letterSpacing: "0.24px",
    },
  },
  zIndex: {
    modal: 9999,
    tooltip: 10000,
  },
  shadows: [
    "none",
    "0px 2px 8px rgba(0, 0, 0, 0.15)",
    "0px 2px 8px rgba(0, 0, 0, 0.15)",
    "0px 2px 8px rgba(0, 0, 0, 0.15)",
    "0px 2px 8px rgba(0, 0, 0, 0.15)",
    "0px 2px 8px rgba(0, 0, 0, 0.15)",
    "0px 2px 8px rgba(0, 0, 0, 0.15)",
    "0px 2px 8px rgba(0, 0, 0, 0.15)",
    "0px 2px 8px rgba(0, 0, 0, 0.15)",
    "0px 2px 8px rgba(0, 0, 0, 0.15)",
    "0px 2px 8px rgba(0, 0, 0, 0.15)",
    "0px 2px 8px rgba(0, 0, 0, 0.15)",
    "0px 2px 8px rgba(0, 0, 0, 0.15)",
    "0px 2px 8px rgba(0, 0, 0, 0.15)",
    "0px 2px 8px rgba(0, 0, 0, 0.15)",
    "0px 2px 8px rgba(0, 0, 0, 0.15)",
    "0px 2px 8px rgba(0, 0, 0, 0.15)",
    "0px 2px 8px rgba(0, 0, 0, 0.15)",
    "0px 2px 8px rgba(0, 0, 0, 0.15)",
    "0px 2px 8px rgba(0, 0, 0, 0.15)",
    "0px 2px 8px rgba(0, 0, 0, 0.15)",
    "0px 2px 8px rgba(0, 0, 0, 0.15)",
    "0px 2px 8px rgba(0, 0, 0, 0.15)",
    "0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)",
    "0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)",
  ],
  shape: {
    borderRadius: 12,
  },
  overrides: {
    MuiDivider: {
      root: { backgroundColor: GREY_300, margin: undefined, height: 0.5 },
    },
    MuiSkeleton: {
      text: {
        borderRadius: "4px",
      },
    },
    MuiButton: {
      root: {
        height: "36px",
        fontSize: "16px",
        borderRadius: 4,
        backgroundColor: "white",
      },
      sizeSmall: {
        height: "30px",
        fontSize: "14px",
      },
      sizeLarge: {
        height: "48px",
      },
      contained: {
        backgroundColor: "white",
      },
      // disableElevation: true,
    },
    MuiIconButton: {
      root: {},
    },
    MuiAppBar: {
      root: {
        padding: "12px 8px 12px 24px",
      },
    },
  },
});
