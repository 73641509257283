import { useMediaQuery } from "@mui/material";
import React from "react";
import DesktopHomePage from "../components/DesktopHomePage";
import MobileHomePage from "../components/MobileHomePage";

function HomePage() {
  const isDesktop = useMediaQuery("(min-width:800px)", {
    noSsr: true,
    defaultMatches: true,
  });
  if (isDesktop) {
    return (
      <>
        <DesktopHomePage />
      </>
    );
  }
  return (
    <>
      <MobileHomePage />
    </>
  );
}

export default HomePage;
