import { Email, Phone } from "@mui/icons-material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import {
  Box,
  Button,
  ButtonBase,
  Card,
  Container,
  Grid,
  Typography
} from "@mui/material";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { PRIMARY } from "../../../configs/colors";
import { ReactComponent as AppStore } from "../../../svg/appstore.svg";
import ImageBuyer from "../../../svg/buyer.jpg";
import { ReactComponent as GooglePlay } from "../../../svg/googlePlay.svg";
import AppIcon from "../../../svg/image_2.svg";
import BackGround from "../../../svg/image_4.jpg";
import Background2 from "../../../svg/image_5.jpg";
import { ReactComponent as Logo } from "../../../svg/logo.svg";
import { ReactComponent as Point } from "../../../svg/point.svg";
import ImageSeller from "../../../svg/seller.jpg";
import { BuyerInfo, fakeInfo, InfoList, SellerInfo } from "../constants";

function MobileHomePage() {
  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);
  return (
    <>
      <Box
        position="relative"
        style={{
          backgroundImage: `url("${BackGround}")`,
          backgroundPosition: "bottom",
          backgroundRepeat: "no-repeat",
          padding: 24,
          backgroundColor: "#e4b243",
          height: 820,
          minHeight: "100vh",
        }}
        color="white"
        textAlign="center"
      >
        <Box textAlign="left">
          <Logo height={36} />
        </Box>
        <Typography variant="h5" style={{ marginTop: 48 }} color="primary">
          <FormattedMessage id="seviMarket" />
        </Typography>
        <Typography variant="h5" color="inherit" style={{ marginTop: 16 }}>
          <FormattedMessage id="seviMarketTitle" />
        </Typography>
        <Typography variant="body1" style={{ marginTop: 24 }} color="inherit">
          <FormattedMessage id="seviMarketContent" />
        </Typography>
        <Box
          marginTop={3}
          width={"100%"}
          borderRadius={8}
          // bgcolor={"rgba(87, 47, 1, 0.3)"}
          padding={1}
        >
          <Box>
            <Typography variant="subtitle1">
              <FormattedMessage id="forBuyer" />
            </Typography>
          </Box>
          <Box display="flex" justifyContent={"center"}>
            <ButtonBase
              href="https://apps.apple.com/vn/app/sevi-d%E1%BB%8Bch-v%E1%BB%A5-quanh-ta/id1600928235"
              target="_blank"
              style={{ marginRight: 24 }}
            >
              <AppStore />
            </ButtonBase>
            <ButtonBase
              href="https://play.google.com/store/apps/details?id=vn.sevi.buyer"
              target="_blank"
            >
              <GooglePlay />
            </ButtonBase>
          </Box>
        </Box>
        <Box
          marginTop={2}
          width={"100%"}
          borderRadius={1}
          bgcolor={"rgba(87, 47, 1, 0.3)"}
          padding={1}
        >
          <Box>
            <Typography variant="subtitle1">
              <FormattedMessage id="forSeller" />
            </Typography>
          </Box>
          <Box display="flex" justifyContent={"center"}>
            <ButtonBase
              href="https://apps.apple.com/vn/app/sevi-seller/id1601013753"
              target="_blank"
              style={{ marginRight: 24 }}
            >
              <AppStore />
            </ButtonBase>
            <ButtonBase
              href="https://play.google.com/store/apps/details?id=vn.sevi.seller"
              target="_blank"
            >
              <GooglePlay />
            </ButtonBase>
          </Box>
        </Box>
      </Box>
      <Container style={{ padding: 24, textAlign: "center" }}>
        <Grid container spacing={2}>
          {fakeInfo.map((value, index) => {
            return (
              <Grid item xs={6} key={index}>
                <Card
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 24,
                    borderRadius: 0,
                  }}
                  elevation={0}
                >
                  <Typography variant="h5" style={{ color: value.color }}>
                    {value.value}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    style={{
                      color: value.color,
                      marginTop: 12,
                      marginBottom: 24,
                    }}
                  >
                    <FormattedMessage id={value.label} />
                  </Typography>
                  <Card
                    style={{ background: "#4030DE", height: 5, width: 56 }}
                  />
                </Card>
              </Grid>
            );
          })}
        </Grid>
        <Typography variant="h5" style={{ marginTop: 48, marginBottom: 8 }}>
          <FormattedMessage id={"title1"} />
        </Typography>
        <Typography
          variant="h4"
          style={{
            marginTop: 8,
            marginBottom: 24,
            textAlign: "center",
          }}
        >
          <FormattedMessage id={"title2"} />
        </Typography>
        <img src={AppIcon} alt="" style={{ width: "100%", height: "auto" }} />
      </Container>
      <Box bgcolor="white">
        <Container style={{ padding: 24 }}>
          <Box>
            <Typography variant="h5">
              <FormattedMessage id={"youAreBuyer"} />
            </Typography>
            <Typography
              variant="h6"
              style={{ marginBottom: 42, marginTop: 12 }}
            >
              <FormattedMessage id={"findBuyer"} />
            </Typography>
            <Box>
              {SellerInfo.map((v, i) => (
                <Box
                  display="flex"
                  key={i}
                  marginBottom={7}
                  alignItems="center"
                >
                  <Point style={{ marginRight: 12, minWidth: 20 }} />
                  <Typography variant="body1" component="div">
                    {
                      <div
                        style={{ lineHeight: 2 }}
                        dangerouslySetInnerHTML={{
                          __html: v,
                        }}
                      />
                    }
                  </Typography>
                </Box>
              ))}
            </Box>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={scrollToTop}
            >
              <FormattedMessage id="register" />
            </Button>
            <Button variant="text" fullWidth size="large" color="primary">
              <Typography variant="body1">
                <FormattedMessage id="policy" />
              </Typography>
            </Button>
          </Box>
          <img
            src={ImageSeller}
            alt=""
            style={{ width: "100%", height: "auto" }}
          />
        </Container>
      </Box>
      <Box>
        <Container style={{ padding: 24 }}>
          <Box>
            <Typography variant="h5">
              <FormattedMessage id={"youAreSeller"} />
            </Typography>
            <Typography
              variant="h6"
              style={{ marginBottom: 42, marginTop: 12 }}
            >
              <FormattedMessage id={"findJob"} />
            </Typography>
            <Box>
              {BuyerInfo.map((v, i) => (
                <Box
                  display="flex"
                  key={i}
                  marginBottom={7}
                  alignItems="center"
                >
                  <Point style={{ marginRight: 12, minWidth: 20 }} />
                  <Typography variant="body1" component="div">
                    {
                      <div
                        style={{ lineHeight: 2 }}
                        dangerouslySetInnerHTML={{
                          __html: v,
                        }}
                      />
                    }
                  </Typography>
                </Box>
              ))}
            </Box>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={scrollToTop}
            >
              <FormattedMessage id="register" />
            </Button>
            <Button variant="text" fullWidth size="large" color="primary">
              <Typography variant="body1">
                <FormattedMessage id="policy" />
              </Typography>
            </Button>
          </Box>
          <img
            src={ImageBuyer}
            alt=""
            style={{ width: "100%", height: "auto" }}
          />
        </Container>
      </Box>
      <Box bgcolor="white">
        <Container
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "48px 24px",
            textAlign: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h4"
            style={{
              marginBottom: 24,
              textTransform: "uppercase",
            }}
          >
            <FormattedMessage id={"title3"} />
          </Typography>
          <Grid container spacing={8} alignContent="center" alignItems="center">
            {InfoList.map((v, i) => (
              <Grid
                item
                xs={12}
                key={i}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    padding: 8,
                    borderRadius: 8,
                    color: "white",
                    backgroundColor: PRIMARY,
                    width: 44,
                    height: 44,
                  }}
                >
                  {v.icon}
                </div>
                <Typography
                  variant="h6"
                  style={{
                    marginTop: 12,
                  }}
                >
                  {v.title}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
      <Box
        style={{
          backgroundImage: `url("${Background2}")`,
          height: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "60px 24px",
            alignItems: "center",
            color: "black",
            textAlign: "center",
          }}
        >
          <Typography variant="h4" color="inherit">
            <FormattedMessage id={"registerPromotion"} />
          </Typography>
          <Typography
            variant="h6"
            style={{
              marginTop: 12,
              marginBottom: 24,
              lineHeight: "140%",
              fontWeight: "normal",
            }}
            color="inherit"
          >
            <FormattedMessage id={"registerPromotionContent"} />
          </Typography>
          <Button
            variant="contained"
            size="large"
            fullWidth
            onClick={scrollToTop}
          >
            <Typography color="white">
              <FormattedMessage id="register" />
            </Typography>
          </Button>
        </Container>
      </Box>
      <Box paddingY={3} paddingX={1}>
        {/* <Container>
          <Typography
            variant="h3"
            style={{ textAlign: "center", marginBottom: 24 }}
          >
            <FormattedMessage id="frequentlyQuestion" />
          </Typography>
          {ListQuestion.map((v, i) => (
            <QuestionBox data={v} key={i} />
          ))}
        </Container> */}
      </Box>
      <Box bgcolor="black" color="white" component={"footer"}>
        <Box
          style={{ padding: "40px 24px", borderBottom: "1px solid #e0e0e0" }}
        >
          <Logo />
          <Box display="flex" marginTop={3}>
            <LocationOnIcon color="inherit" />
            <Typography
              variant="body2"
              color="inherit"
              style={{ marginLeft: 16, marginRight: 24 }}
            >
              16/17 Hoàng Ngọc Phách, Đống Đa, Hà Nội
            </Typography>
          </Box>
          <Box display="flex" marginTop={3}>
            <Email color="inherit" />
            <Typography
              variant="body2"
              color="inherit"
              style={{ marginLeft: 16, marginRight: 24 }}
            >
              contact@sevi.vn
            </Typography>
          </Box>{" "}
          <Box display="flex" marginTop={3}>
            <Phone color="inherit" />
            <Typography
              variant="body2"
              color="inherit"
              style={{ marginLeft: 16 }}
            >
              0904391987
            </Typography>
          </Box>
        </Box>
        <Box style={{ padding: 24 }} color="#CCCCCC">
          <Box flex={1}>
            <Typography
              variant="caption"
              color="inherit"
              style={{ marginLeft: 16, marginRight: 24 }}
            >
              © 2021 Sevi Vietnam Technology Development LLC
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" marginTop={3}>
            <Typography
              variant="caption"
              color="inherit"
              style={{ marginLeft: 16, marginRight: 24 }}
            >
              <a
                href="/terms"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Điều khoản sử dụng dịch vụ và chính sách bảo mật
              </a>
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default MobileHomePage;
