import { Route, Switch } from "react-router";
import "./App.css";
import HomePage from "./modules/home/page/HomePage";
import StatsPage from "./modules/home/page/StatsPage";
import TermsPage from "./modules/home/page/TermsPage";
import { DeleteAccountGuideBuyer } from "./modules/home/page/DeleteAccountGuideBuyer";
import { DeleteAccountGuideSeller } from "./modules/home/page/DeleteAccountGuideSeller";

function App() {
  return (
    <Switch>
      <Route path="/" exact component={HomePage} />
      <Route path="/terms" component={TermsPage} />
      <Route path="/stats" component={StatsPage} />
      <Route path="/delete-buyer-account" component={DeleteAccountGuideBuyer} />
      <Route
        path="/delete-seller-account"
        component={DeleteAccountGuideSeller}
      />
    </Switch>
  );
}

export default App;
