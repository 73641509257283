
export const BACKGROUND = "#ffffff";

export const BLUE = "#276ef1";
export const BLUE_300 = "#5a90f4";
export const BLUE_200 = "#9fbff8";
export const BLUE_100 = "#e6f6fe";
export const BLUE_50 = "#edf3fd";

export const GREY = "#212121";
export const GREY_900 = "#212121";
export const GREY_700 = "#616161";
export const GREY_600 = "#757575";
export const GREY_30000 = "#9e9e9e";
export const GREY_400 = "#bdbdbd";
export const GREY_300 = "#e0e0e0";
export const GREY_100 = "#f5f5f5";
export const GREY_3000 = "#fafafa";

export const PRIMARY = "#ba1cde";
export const SECONDARY = "#ffcb14";
export const BLACK_TEXT = "#05050e";
export const BLACK = "#05050e";
export const LIGHT_GREY = "#f5f6f9";
export const RED = "#ea0839";
export const DARK_RED = "#B60158";

export const GREEN = "#38b000";
