export function DeleteAccountGuideBuyer() {
  return (
    <main style={{ padding: "12px" }}>
      <div>
        <h4>
          Instruction to delete account on app (Hướng dẫn xóa tài khoản trên ứng
          dụng) <span style={{ fontWeight: 600 }}> Sevi - Dịch vụ quanh ta</span>
        </h4>
        <ol>
          <li>
            Sign into your account on the app (Đăng nhập vào tài khoản của bạn
            trên ứng dụng)
          </li>
          <li>
            Enter the profile area of the app by clicking on the last item in
            the navigation bar at the bottom of the screen (Vào khu vực hồ sơ
            của ứng dụng bằng cách ấn vào mục cuối cùng trên thanh điều hướng ở
            đáy màn hình)
          </li>
          <li>
            Click on the three dots button at the top right of the screen to
            open the action menu for the profile area (Bấm vào nút ba chấm ở
            trên cùng bên phải để mở danh mục hành động cho mục hồ sơ)
          </li>
          <li>
            Choose the "Delete account" action at the bottom of the action menu
            and confirm that you want to delete your account (Chọn hành động
            "Xóa tài khoản" được đặt ở dưới cùng của danh mục và xác nhận bạn
            muốn xóa tài khoản)
          </li>
        </ol>
      </div>
      <div>
        <p>
          All your account information such as name, address, phone number,
          avatar and password will be deleted immediately from the system when
          you choose to delete your account. (Tất cả thông tin tài khoản của bạn
          như tên địa chỉ, số điện thoại, mật khẩu, ảnh hồ sơ sẽ được xóa ngay
          lập tức khỏi hệ thống khi bạn chọn xóa tài khoản)
        </p>
        <p>
          All transactions you made in the app and their corresponding reviews
          will remained in the database under unknown name (Tất cả giao dịch bạn
          tạo trên ứng dụng và các đánh giá tương ứng sẽ vẫn được lưu trên cơ sở
          dữ liệu của ứng dụng dưới tên khuyết danh)
        </p>
      </div>
    </main>
  );
}
