
import { Box, Typography } from "@mui/material";
import * as React from "react";

interface ITermsPageProps { }

const TermsPage: React.FunctionComponent<ITermsPageProps> = (props) => {
  return (
    <Box margin={3}>
      <Box style={{ textAlign: "center" }}>
        <Typography variant="h5">ĐIỀU KHOẢN SỬ DỤNG</Typography>
      </Box>
      <Box>
        <Typography>
          Chào mừng bạn đến Ứng dụng cung cấp dịch vụ thương mại điện tử (TMĐT)
          là Ứng dụng “SEVI”, do Công ty TNHH phát triển công nghệ SEVI Việt Nam
          (Công Ty”) xây dựng và vận hành nhằm cung cấp dịch vụ trung gian kết
          nối Thợ ( Người cung cấp dịch vụ) và Người sử dụng Dịch Vụ (“Khách
          hàng”) bao gồm tất cả các loại sửa chữa điện nước, vận chuyển đồ đạc,
          nâng cấp, cải tạo và thay thế trang thiết bị trong gia đình, văn
          phòng, Khách hàng có thêm phương thức để nhanh chóng tìm kiếm các đối
          tượng có khả năng cung ứng những Dịch vụ này thông qua nền tảng công
          nghệ.
        </Typography>
      </Box>
      <Box marginTop={2}>
        <Typography variant="h6">ĐIỀU 1. QUY ĐỊNH CHUNG </Typography>
        <Typography>
          <strong>1.1 </strong>
          SEVI giữ mọi quyền điều chỉnh nội dung của của Thỏa thuận này. Mọi
          thay đổi sẽ được thông báo tại Ứng Dụng SEVI cho Người Dùng ít nhất 10
          ngày trước khi áp dụng những thay đổi đó. Trong trường hợp Người Sử
          Dụng không đồng ý với bất kỳ sự thay đổi nào mà Công Ty đưa ra thì có
          quyền chấm dứt thoả thuận này.
        </Typography>
        <Typography>
          <strong>1.2 </strong>
          Thỏa Thuận không xác định thời hạn và chấm dứt tùy vào điều kiện nào
          xảy ra trước: Người Dùng vi phạm chính sách của SEVI dẫn tới bị khóa
          vĩnh viễn tài khoản; Người Dùng chấm dứt Thỏa Thuận; Ứng dụng SEVI
          không còn tồn tại.
        </Typography>
        <Typography>
          <strong>1.3 </strong>
          SEVI là bên trung gian kết nối giữa Thợ (Người cung cấp dịch vụ)và
          khách hàng, SEVI không bảo đảm cho việc hoàn thành giao dịch trên thực
          tế giữa những Người Sử Dụng.
        </Typography>
      </Box>
      <Box marginTop={2}>
        <Typography variant="h6">ĐIỀU 2. ĐỊNH NGHĨA </Typography>
        <Typography>
          <strong>2.1. SEVI: </strong>
          là Ứng dụng cung cấp dịch vụ thương mại điện tử (TMĐT) là Ứng dụng
          “SEVI”, do Công ty Công ty TNHH phát triển công nghệ SEVI Việt Nam
          (Công Ty”) xây dựng và vận hành nhằm cung cấp dịch vụ trung gian kết
          nối Thợ ( Người cung cấp dịch vụ)cung cấp Dịch Vụ (“Thợ”) và Người sử
          dụng Dịch Vụ (“Khách hàng”) bao gồm tất cả các loại sửa chữa điện
          nước, vận chuyển đồ đạc, nâng cấp, cải tạo và thay thế trang thiết bị
          trong gia đình, văn phòng, Khách hàng có thêm phương thức để nhanh
          chóng tìm kiếm các đối tượng có khả năng cung ứng những Dịch vụ này
          thông qua nền tảng công nghệ.
        </Typography>
        <Typography>
          <strong>2.2. Hợp đồng Cung ứng Dịch vụ: </strong>
          là hợp đồng giữa Thợ (Người cung cấp dịch vụ)và khách hàng về việc
          thực hiện một công việc cụ thể.
        </Typography>
        <Typography>
          <strong>2.3. Người Sử Dụng: </strong>
          là Thợ (Người cung cấp dịch vụ) và khách hàng.
        </Typography>
        <Typography>
          <strong>2.4. Khách hàng: </strong>
          là cá nhân hoặc tổ chức có nhu cầu sử dụng dịch vụ của Thợ (Người cung
          cấp dịch vụ) được cung ứng trên Ứng dụng SEVI. Khách hàng bắt buộc
          đăng ký tài khoản để sử dụng Ứng dụng SEVI.
        </Typography>
        <Typography>
          <strong>2.5. Thợ (Người cung cấp dịch vụ): </strong>
          là cá nhân hoặc pháp nhân có nhu cầu cung cấp dịch vụ trên Ứng dụng
          SEVI. Thợ (Người cung cấp dịch vụ) đăng ký cung cấp dịch vụ bằng việc
          đăng ký Tài Khoản tại Ứng dụng SEVI và Công Ty xét duyệt trước khi
          tham gia cung ứng Dịch vụ cho Khách hàng.
        </Typography>
        <Typography>
          <strong>2.5. Thợ( Người cung cấp dịch vụ): </strong>
          là cá nhân hoặc pháp nhân có nhu cầu cung cấp dịch vụ trên Ứng dụng
          SEVI. Thợ (Người cung cấp dịch vụ) đăng ký cung cấp dịch vụ bằng việc
          đăng ký Tài Khoản tại Ứng dụng SEVI và Công Ty xét duyệt trước khi
          tham gia cung ứng Dịch vụ cho Khách hàng.
        </Typography>
        <Typography>
          <strong>2.6. Giá thanh toán: </strong>
          là chi phí mà Thợ (Người cung cấp dịch vụ)đưa ra để Khách hàng xem xét
          và thanh toán khi sử dụng Dịch vụ của Thợ.
        </Typography>
        <Typography>
          <strong>2.7. Phí Dịch vụ TMĐT: </strong>
          15.000 VNĐ ( Mười lăm nghìn đồng) là khoản phí để sử dụng Ứng dụng
          SEVI do Công Ty thu trên mỗi giao dịch giữa Thợ (Người cung cấp dịch
          vụ) và khách hàng.
        </Typography>
      </Box>
      <Box marginTop={2}>
        <Typography variant="h6">
          ĐIỀU 3. CAM KẾT VÀ BẢO ĐẢM CỦA SEVI{" "}
        </Typography>
        <Typography>
          <strong>3.1. </strong>
          SEVI bảo đảm thiết lập ứng dụng hợp pháp, không vi phạm điều cấm của
          luật, tuân thủ đúng các cam kết về quyền và nghĩa vụ của các bên liên
          quan.
        </Typography>
        <Typography>
          <strong>3.2. </strong>
          SEVI cam kết thông tin, dữ liệu của Người Sử Dụng được sử dụng đúng
          mục đích như thoả thuận tại Điều 14.
        </Typography>
        <Typography>
          <strong>3.3. </strong>
          SEVI luôn mong muốn và sẵn sàng hợp tác trên tinh thần thiện chí, công
          khai, minh bạch, các bên cùng có lợi.
        </Typography>
      </Box>
      <Box marginTop={2}>
        <Typography variant="h6">
          ĐIỀU 4. CAM KẾT VÀ BẢO ĐẢM CỦA NGƯỜI SỬ DỤNG
        </Typography>
        <Typography>
          Bằng cách sử dụng Dịch Vụ trên SEVI, Người Sử Dụng cam kết:
        </Typography>
        <Typography>
          <strong>4.1. </strong>
          Cho phép SEVI thu thập, sử dụng, công bố và/hoặc xử lý các Nội Dung,
          dữ liệu cá nhân và Thông Tin Người Sử Dụng như được quy định trong
          Chính Sách Bảo Mật.
        </Typography>
        <Typography>
          <strong>4.2. </strong>
          Đồng ý và công nhận rằng các thông tin được cung cấp trên SEVI sẽ
          thuộc sở hữu chung của Người Sử Dụng và SEVI.
        </Typography>
        <Typography>
          <strong>4.3. </strong>
          Người Sử Dụng ủy quyền cho SEVI và SEVI có quyền điều chỉnh, thay đổi,
          bổ sung, chấm dứt tài khoản của Người Sử Dụng khi SEVI có cơ sở để xác
          định có sự nhầm lẫn liên quan đến thông tin của Người Sử Dụng, hoặc do
          Người Sử Dụng vi phạm các cam kết và nghĩa vụ.
        </Typography>
        <Typography>
          <strong>4.4. </strong>
          Các thông tin cung cấp là chính xác, đầy đủ và hợp pháp để sử dụng
          Dịch vụ. Trong trường hợp SEVI phát hiện, nhận thấy các thông tin là
          không đúng, không chính xác thì có quyền đơn phương chấm dứt việc cho
          phép Thợ ( Người cung cấp dịch vụ)tiếp tục sử dụng SEVI mà không phải
          thông báo cho bên kia.
        </Typography>
        <Typography>
          <strong>4.5. </strong>
          Tôn trọng quyền sở hữu trí tuệ của Chủ sở hữu ứng dụng SEVI và các sản
          phẩm khác có liên quan, không sao chép, phân phối các sản phẩm trí tuệ
          mà không có sự đồng ý bằng văn bản của Chủ sở hữu.
        </Typography>
        <Typography>
          <strong>4.6. </strong>
          Chỉ sử dụng Ứng dụng SEVI cho các mục đích hợp pháp, không dùng SEVI
          để gửi hoặc lưu trữ bất kỳ tài liệu bất hợp pháp hoặc có dấu hiệu vi
          phạm pháp luật nào.
        </Typography>
      </Box>
      <Box marginTop={2}>
        <Typography variant="h6">ĐIỀU 5: SẢN PHẨM VÀ DỊCH VỤ</Typography>
        <Typography>
          <strong>5.1. </strong>
          SEVI là ứng dụng cung cấp dịch vụ kết nối giữa Thợ ( Người cung cấp
          dịch vụ) và Khách hàng để ký kết Hợp đồng Cung ứng dịch vụ đáp ứng nhu
          cầu nhất định của mỗi bên.
        </Typography>
        <Typography>
          <strong>5.2. </strong>
          SEVI là bên trung gian, hỗ trợ quản lý thông tin giữa những người sử
          dụng nhưng không là người chịu trách nhiệm cho bất kỳ tranh chấp nào
          phát sinh từ các giao dịch giữa những Người sử dụng nói chung và Hợp
          đồng cung ứng dịch vụ giữa Thợ (Người cung cấp dịch vụ)và khách hàng
          nói riêng. SEVI có thể hoặc không sàng lọc trước Người Sử Dụng hoặc
          Nội dung hoặc thông tin cung cấp bởi Người Sử Dụng.
        </Typography>
      </Box>
      <Box marginTop={2}>
        <Typography variant="h6">ĐIỀU 6: THANH TOÁN PHÍ VÀ GIÁ </Typography>
        <Typography>
          <strong>6.1 PHÍ DỊCH VỤ TMĐT</strong>
        </Typography>
        <Typography>
          <strong>6.1.1 </strong>
          Mỗi Hợp đồng Cung ứng dịch vụ được ký kết giữa Thợ (Người cung cấp
          dịch vụ)và Khách hàng sẽ được tính phí 15.000 VNĐ (Mười lăm nghìn Việt
          nam Đồng) do Thợ ( Người cung cấp dịch vụ)chịu trách nhiệm chi trả.
        </Typography>
        <Typography>
          <strong>6.1.2 </strong>
          Thời hạn thanh toán: Ngay sau khi Thợ (Người cung cấp dịch vụ)và Khách
          hàng thực hiện giao kèo về việc cung ứng dịch vụ.
        </Typography>
        <Typography>
          <strong>6.1.3 </strong>
          Phương thức thanh toán: Phí dịch vụ sẽ bị trừ trực tiếp vào tài khoản
          của Thợ trên ứng dụng SEVI thợ sau khi thợ nạp tiền.
        </Typography>
        <Typography>
          <strong>6.2 GIÁ THANH TOÁN </strong>
        </Typography>
        <Typography>
          <strong>6.2.1 </strong>
          Ứng dụng SEVI cung cấp trung gian các Dịch vụ cung ứng bởi Thợ (Người
          cung cấp dịch vụ) và đáp ứng nhu cầu của Khách hàng. Ứng dụng SEVI
          không tham gia vào quá trình thanh toán Giá giữa Người Dùng.
        </Typography>
        <Typography>
          <strong>6.2.2 </strong>
          Khách hàng có toàn quyền thoản thuận với Thợ (Người cung cấp dịch vụ)
          để thanh toán trực tiếp cho Thợ ( Người cung cấp dịch vụ) mà không
          thông qua Ứng dụng SEVI.
        </Typography>
        <Typography>
          <strong>6.2.3 </strong>
          Thời hạn và Phương thức thanh toán trong Hợp đồng cung ứng dịch giữa
          Thợ ( Người cung cấp dịch vụ) và Khách hàng do hai bên thoả thuận,
          không thông qua SEVI.
        </Typography>
      </Box>
      <Box marginTop={2}>
        <Typography variant="h6">
          ĐIỀU 7. QUYỀN VÀ NGHĨA VỤ NGƯỜI SỬ DỤNG
        </Typography>
        <Typography>
          <strong>7.1. Quyền và nghĩa vụ của Thợ </strong>
        </Typography>
        <Typography>
          <strong>7.1.1 Quyền của Thợ </strong>
        </Typography>
        <ul>
          <li>
            Thợ ( Người cung cấp dịch vụ) sở hữu, hoặc có quyền sử dụng hợp pháp
            các trang thiết bị công cụ khi tiếp nhận các Dịch vụ từ Khách hàng,
            đảm bảo các trang thiết bị, công cụ đang trong tình trạng vận hành
            tốt và đáp ứng các tiêu chuẩn an toàn.
          </li>
          <li>
            Thợ ( Người cung cấp dịch vụ) có quyền thương lương giá cả dịch vụ
            với khách hàng phù hợp với khả năng của mình
          </li>
          <li>
            Thợ ( Người cung cấp dịch vụ) có quyền sử dụng Ứng dụng SEVI Thợ khi
            đáp ứng đủ các yêu cầu trong thỏa thuận này
          </li>
          <li>
            Thợ ( Người cung cấp dịch vụ) có quyền yêu cầu SEVI giải đáp thắc
            mắc trong quá trình sử dụng Ứng dụng SEVI
          </li>
          <li>
            Thợ ( Người cung cấp dịch vụ) đồng ý sẽ thông báo cho Công Ty về bất
            kỳ trường hợp nào sử dụng trái phép tài khoản và mật khẩu hoặc bất
            kỳ các hành động phá vỡ hệ thống bảo mật nào. Thợ có quyền yêu cầu
            dừng ngay mọi hoạt động liên quan đến tài khoản đang bị sử dụng trái
            phép.
          </li>
          <li>
            Thợ ( Người cung cấp dịch vụ) được đánh giá năng lực qua ứng dụng
            SEVI theo Rating của người dùng đã sử dụng, càng được đánh giá nhiều
            sao thì khả năng uy tín của càng được nâng cao.
          </li>
        </ul>
        <Typography>
          <strong>7.1.2 Nghĩa vụ của Thợ </strong>
        </Typography>
        <ul>
          <li>
            Cung ứng các dịch vụ và thực hiện những công việc có liên quan một
            cách đầy đủ, phù hợp theo thoả thuận với khách hàng. Bảo quản và
            giao lại cho khách hàng tài liệu và phương tiện được giao để thực
            hiện dịch vụ sau khi hoàn thành công việc;
          </li>
          <li>
            Thợ ( Người cung cấp dịch vụ) có nghĩa vụ thanh toán khoản phí Điều
            6 cho SEVI ngay sau khi thỏa thuận cung ứng dịch vụ giữa Thợ ( Người
            cung cấp dịch vụ) và Khách hàng được hoàn thành trên SEVI.
          </li>
          <li>
            Thông báo ngay cho khách hàng trong trường hợp thông tin, tài liệu
            không đầy đủ, phương tiện không bảo đảm để hoàn thành việc cung ứng
            dịch vụ.
          </li>
          <li>
            Giữ bí mật về thông tin mà mình biết được trong quá trình cung ứng
            dịch vụ.
          </li>
          <li>
            Thợ ( Người cung cấp dịch vụ) cam đoan, đảm bảo và đồng ý có đủ năng
            lực, kiến thức, chuyên môn bằng cấp (nếu có) hay điều kiện kinh
            doanh theo quy định về Dịch vụ đã đăng ký cung cấp tại Ứng dụng SEVI
          </li>
          <li>
            Thợ ( Người cung cấp dịch vụ) sở hữu, hoặc có quyền sử dụng hợp pháp
            để sử dụng các trang thiết bị công cụ khi tiếp nhận các Dịch vụ từ
            Khách hàng, đảm bảo các trang thiết bị, công cụ đang trong tình
            trạng vận hành tốt và đáp ứng các tiêu chuẩn an toàn.
          </li>
          <li>
            Thợ ( Người cung cấp dịch vụ) chịu trách nhiệm với bất kỳ nghĩa vụ
            phát sinh từ tổn thất hay thiệt hại trong quá trình thực hiện Hợp
            đồng cung ứng dịch vụ với Khách hàng.
          </li>
          <li>
            Thợ ( Người cung cấp dịch vụ) có quyền sử dụng Ứng dụng SEVI vì mục
            đích sử dụng hợp pháp, không được phép (i) gửi thư rác, spam (ii)
            gửi hoặc lưu trữ tài liệu xâm phạm, khiêu dâm, đe dọa, bôi nhọ, xâm
            phạm an ninh quốc gia hoặc các tài liệu trái pháp luật hoặc mang
            tính nguy hại (iii) gửi, phát tán tài liệu có chứa mã độc, virus,
            sâu máy tính, đoạn mã, tập tin, thông điệp, chương tình máy tính độc
            hại (iv)… và các hành vi tương tự khác.
          </li>
          <li>
            Thợ ( Người cung cấp dịch vụ)và SEVI cùng phối hợp, tạo điều kiện và
            hỗ trợ nhau trong việc yêu cầu hoặc xác minh bất kỳ các vấn đề liên
            quan tới thuế, phí, lệ phí, giảm giá, khấu trừ thuế, nộp thêm hoặc
            hoàn lại của bất kỳ khoản thuế nào liên quan tới các Dịch vụ được
            cung ứng của Ứng dụng SEVI để đảm bảo việc tuân thủ pháp luật của
            các bên.
          </li>
          <li>Thực hiện quyền và trách nhiệm khác theo quy định pháp luật.</li>
        </ul>
        <Typography>
          <strong>7.2 Quyền và nghĩa vụ của Khách hàng </strong>
        </Typography>
        <Typography>
          <strong>7.2.1 Quyền của khách hàng </strong>
        </Typography>
        <ul>
          <li>
            Khách hàng được SEVI bảo mật các thông tin các nhân theo quy định về
            bảo mật thông tin Khách hàng của SEVI
          </li>
          <li>
            Khách hàng được quyền lựa chọn các loại hình dịch vụ mà bên Thợ (
            Người cung cấp dịch vụ) cung cấp để đáp ứng nhu cầu của khách hàng
          </li>
          <li>
            Khách hàng chỉ có thể truy cập ứng dụng bằng cách sử dụng các phương
            tiện, thiêt bị di động cho phép, khách hàng có trách nhiệm kiểm tra
            để đảm bảo rằng đã tải về đúng ứng dụng cho thiết bị của mình.
          </li>
          <li>
            Khách hàng đồng ý sẽ thông báo ngay cho Công Ty về bất kỳ trường hợp
            nào sử dụng trái phép tài khoản và mật khẩu của bạn hoặc bất kỳ các
            hành động phá vỡ hệ thống bảo mật nào. Khách hàng cũng bảo đảm rằng
            luôn thoát tài khoản của mình sau mỗi lần sử dụng.
          </li>
          <li>
            Khách hàng có quyền yêu cầu Thợ ( Người cung cấp dịch vụ) bảo hành,
            bảo dưỡng dịch vụ đã thực hiện theo thỏa thuận. Việc bảo hành hoặc
            bảo dưỡng có phát sinh thêm chi phí SEVI hoàn toàn không liên đới
            trách nhiệm.
          </li>
        </ul>
        <Typography>
          <strong>7.2.2 Nghĩa vụ của khách hàng </strong>
        </Typography>
        <ul>
          <li>
            Khách hàng có trách nhiệm cung cấp các thông tin cần thiết liên quan
            đến bản thân khi đăng ký sử dụng ứng dụng: Họ và tên, chứng minh
            nhân dân, giới tính, ngày tháng năm sinh, địa chỉ, email, số điện
            thoại.
          </li>
          <li>
            Khách hàng chịu trách nhiệm toàn bộ các vấn đề thuộc về bản quyền
            nội dung, hình ảnh, thông tin cung cấp và chịu trách nhiệm về tính
            chính xác, trung thực của thông tin về của bản thân đăng tải trên
            Ứng dụng SEVI
          </li>
          <li>
            Khách hàng có trách nhiệm bảo mật thông tin tài khoản, nếu những
            thông tin trên bị tiết lộ dưới bất kỳ hình thức nào không phải do
            SEVI thì Khách hàng phải chấp nhận những rủi ro phát sinh. Công Ty
            sẽ căn cứ vào những thông tin hiện có trong tài khoản để làm căn cứ
            quyết định chủ sở hữu tài khoản nếu có tranh chấp và Công Ty sẽ
            không chịu trách nhiệm về mọi tổn thất phát sinh.
          </li>
          <li>
            Khách hàng phải tuân thủ tuyệt đối quy định về các hành vi cấm, các
            nội dung trao đổi cung cấp thông tin được quy định trong Điều khoản
            sử dụng, Quy chế hoạt động của Ứng dụng. Nếu vi phạm một hoặc nhiều
            hành vi, tùy thuộc vào mức độ vi phạm Công Ty sẽ khóa tài khoản vĩnh
            viễn, tước bỏ mọi quyền lợi của bạn đối các dịch vụ của Công Ty và
            sẽ yêu cầu cơ quan chức năng can thiệp hợp pháp.
          </li>
          <li>
            Khách hàng chịu mọi trách nhiệm trước pháp luật nếu có những hành vi
            vi phạm pháp luật xảy ra giữa khách hàng và Thợ.
          </li>
          <li>
            Khách hàng phải tuân thủ, chịu trách nhiệm giao kết giữa Thợ ( Người
            cung cấp dịch vụ)và mình về các nội dung công việc mà Thợ ( Người
            cung cấp dịch vụ)thực hiện, tiền công mà Thợ ( Người cung cấp dịch
            vụ)được nhận, các vấn đề khác trong thỏa thuận giữa hai bên.
          </li>
          <li>
            Khách hàng không được lợi dụng app SEVI để thực hiện những hành vi
            vi phạm pháp luật.
          </li>
          <li>
            Khách hàng phải thanh toán đầy đủ chi phí dịch vụ đã giao kèo với
            Thợ.
          </li>
          <li>Thực hiện quyền và trách nhiệm khác theo quy định pháp luật.</li>
        </ul>
      </Box>
      <Box marginTop={2}>
        <Typography variant="h6">ĐIỀU 8. QUYỀN VÀ NGHĨA VỤ CỦA SEVI</Typography>
        <Typography>
          <strong>8.1 </strong>
          SEVI bảo lưu quyền điều chỉnh, thay đổi hoặc sửa đổi Quy chế hoạt
          động, chính sách, Điều khoản sử dụng dành cho Thợ ( Người cung cấp
          dịch vụ) và các văn bản trên liên kết khác. Những điều chỉnh, thay đổi
          và sửa đổi nói trên sẽ có thông báo cập nhập cho Người Dùng trước 5
          ngày trước khi áp dụng những thay đổi đó
        </Typography>
        <Typography>
          <strong>8.2 </strong>
          SEVI bảo lưu quyền từ chối yêu cầu mở Tài Khoản hoặc các truy cập của
          bạn tới Trang SEVI hoặc Dịch Vụ theo quy định pháp luật và Điều khoản
          dịch vụ.
        </Typography>
        <Typography>
          <strong>8.3 </strong>
          SEVI có quyền nhận khoản phí thanh toán theo Điều 6 tính trên mỗi Hợp
          đồng Cung ứng dịch vụ được giao kết giữa Thợ ( Người cung cấp dịch vụ)
          và Khách hàng.
        </Typography>
        <Typography>
          <strong>8.4 </strong>
          SEVI Không được tiết lộ, cung cấp thông tin làm phương hại đến lợi ích
          của Người Sử Dụng
        </Typography>
        <Typography>
          <strong>8.5 </strong>
          SEVI có trách nhiệm giải đáp các thắc mắc của Người Sử Dụng trong quá
          trình sử dụng Ứng dụng SEVI.
        </Typography>
      </Box>
      <Box marginTop={2}>
        <Typography variant="h6">
          ĐIỀU 9. TRANH CHẤP, KHIẾU NẠI VÀ BIỆN PHÁP KHẮC PHỤC
        </Typography>
        <Typography>
          <strong>9.1 </strong>
          Mọi khiếu nại phát sinh liên quan đến Điều khoản sử dụng, chính sách
          bảo mật, tài khoản, quy chế hoạt động thì Người Sử Dụng có quyền thông
          báo cho Ban quản lý ứng dụng SEVI để kịp thời giải quyết, sửa chữa.
        </Typography>
        <Typography>
          <strong>9.2 </strong>
          Mọi tranh chấp liên quan đến Điều khoản sử dụng giữa Người Sử Dụng và
          SEVI trước tiên được giải quyết bằng thương lượng. Trong trường hợp
          Người Sử Dụng và SEVI không thoả thuận được thì Phán quyết của SEVI là
          phán quyết cuối cùng và có hiệu lực với tất cả các bên.
        </Typography>
        <Typography>
          <strong>9.3 </strong>
          Những vấn đề chưa được quy định trong Thỏa Thuận này sẽ được Các Bên
          thống nhất áp dụng quy định của Pháp luật.
        </Typography>
      </Box>
      <Box marginTop={2}>
        <Typography variant="h6">ĐIỀU 10. SỰ KIỆN BẤT KHẢ KHÁNG.</Typography>
        <Typography>
          <strong>10.1 </strong>
          Sự kiện bất khả kháng là các trường hợp nằm ngoài tầm kiểm soát của
          một Bên, như thiên tai, hoả hoạn, lũ lụt, bão, động đất hay các hiện
          tượng tự nhiên gây nguy hại, dịch bệnh, chiến tranh hoặc đe dọa chiến
          tranh, sự thay đổi của pháp luật, điều ước quốc tế, các hiệp định quốc
          tế, đình công, bãi công, hoặc biểu tình, sự tắc nghẽn mạng lưới
          internet trên diện rộng và ảnh hưởng trực tiếp đến việc thực hiện toàn
          bộ hoặc một phần nghĩa vụ của Thoả Thuận này. Sự kiện bất khả kháng
          xảy ra một cách khách quan không thể lường trước được và không thể
          khắc phục được mặc dù đã áp dụng mọi biện pháp cần thiết và khả năng
          cho phép .
        </Typography>
        <Typography>
          <strong>10.2 </strong>
          Trường hợp bên có nghĩa vụ không thực hiện đúng nghĩa vụ do sự kiện
          bất khả kháng thì không phải chịu trách nhiệm dân sự, trừ trường hợp
          có thỏa thuận khác hoặc pháp luật có quy định khác.
        </Typography>
      </Box>
      <Box marginTop={2}>
        <Typography variant="h6">ĐIỀU 11. CHẤM DỨT HỢP ĐỒNG</Typography>
        <Typography>Các trường hợp chấm dứt hợp đồng: </Typography>
        <Typography>
          <strong>11.1 </strong>
          Theo thoả thuận SEVI và Người Sử Dụng.
        </Typography>
        <Typography>
          <strong>11.2 </strong>
          Người Sử Dụng không tuân thủ tuyệt đối quy định trong Điều khoản sử
          dụng, quy chế hoạt động của Ứng dụng SEVI. Tùy thuộc vào mức độ vi
          phạm SEVI sẽ khóa tài khoản vĩnh viễn, tước bỏ mọi quyền lợi của Người
          Sử Dụng đối với các sản phẩm của SEVI và chấm dứt hợp đồng.
        </Typography>
        <Typography>
          <strong>11.3 </strong>
          Theo yêu cầu của cơ quan nhà nước có thẩm quyền.
        </Typography>
      </Box>
      <Box marginTop={2}>
        <Typography variant="h6">ĐIỀU 12. TÀI KHOẢN VÀ BẢO MẬT </Typography>
        <Typography>
          <strong>12.1 </strong>
          SEVI cam kết bảo mật thông tin của Người Sử Dụng. SEVI sẽ không tiết
          lộ trực tiếp hoặc gián tiếp các Thông Tin Người Sử Dụng cho bất kỳ bên
          thứ ba nào, hoặc bằng bất kỳ phương thức nào cho phép bất kỳ bên thứ
          ba nào được truy cập hoặc sử dụng Thông Tin Người Sử dụng. Trừ trường
          hợp bất khả kháng hoặc yêu cầu của cơ quan nhà nước có thẩm quyền.
        </Typography>
        <Typography>
          <strong>12.2 </strong>
          Người Sử Dụng phải có trách nhiệm bảo mật thông tin tài khoản của
          mình, nếu thông tin trên bị tiết lộ dưới bất kỳ hình thức nào do lỗi
          của Người Sử Dụng thì Người Sử Dụng phải chịu rủi ro phát sinh (nếu
          có).
        </Typography>
        <Typography>
          <strong>12.3 </strong>
          Trường hợp Người Sử Dụng sở hữu dữ liệu cá nhân của Người Sử Dụng khác
          thông qua việc sử dụng Dịch Vụ (“Bên Nhận Thông Tin”) theo đây đồng ý
          rằng, mình sẽ (i) tuân thủ mọi quy định pháp luật về bảo vệ an toàn
          thông tin cá nhân liên quan đến những thông tin đó; (ii) cho phép
          Người Sử Dụng là chủ sở hữu của các thông thông tin cá nhân mà Bên
          Nhận Thông Tin thu thập được (“Bên Tiết Lộ Thông Tin”) được phép xóa
          bỏ thông tin của mình được thu thập từ cơ sở dữ liệu của Bên Nhận
          Thông Tin; và (iii) cho phép Bên Tiết Lộ Thông Tin rà soát những thông
          tin đã được thu thập về họ bởi Bên Nhận Thông Tin, phù hợp với hoặc
          theo yêu cầu của các quy định pháp luật hiện hành.
        </Typography>
      </Box>
      <Box marginTop={2}>
        <Typography variant="h6">
          ĐIỀU 13. HIỆU LỰC ĐIỀU KHOẢN SỬ DỤNG
        </Typography>
        <Typography>
          Các điều khoản sử dụng này có hiệu lực từ ngày Người Sử Dụng bấm nút
          “Đăng ký” và đương nhiên chấm dứt khi tài khoản của Người Sử Dụng bị
          khoá hoặc không được phép sử dụng.
        </Typography>
      </Box>
      <Box marginTop={2}>
        <Typography variant="h6">ĐIỀU 14. ĐIỀU KHOẢN CUỐI CÙNG </Typography>
        <Typography>
          <strong>14.1 </strong>
          Công Ty giữ mọi quyền điều chỉnh nội dung của của Thỏa thuận này. Mọi
          thay đổi sẽ được thông báo tại Ứng Dụng SEVI cho Người Dùng ít nhất 10
          ngày trước khi áp dụng những thay đổi đó. Trong trường hợp Người dùng
          không đồng ý với bất kỳ sự thay đổi nào mà Công Ty đưa ra, Người Dùng
          có quyền chấm dứt Thoả Thuận.
        </Typography>
        <Typography>
          <strong>14.2 </strong>
          Các tranh chấp phát sinh liên quan giữa Thợ ( Người cung cấp dịch
          vụ)và Khách hàng SEVI hoàn toàn không chịu trách nhiệm. Trong khả năng
          cho phép SEVI sẽ hỗ trợ tối đa cung cấp thông tin liên quan.
        </Typography>
        <Typography>
          <strong>14.3 </strong>
          Mọi tranh chấp liên quan đến giao dịch điện tử giữa Người Dùng trước
          tiên được giải quyết bằng thương lượng và hòa giải. Nếu trong trường
          hợp các Người Dùng không thoả thuận, thương lượng, hòa giải được trong
          một khoảng thời gian hợp lý thì vụ việc được đưa ra toà án có thẩm
          quyền giải quyết. SEVI có quyền tham gia hỗ trợ giải quyết các tranh
          chấp, khiếu nại giữa hai bên nhưng SEVI sẽ không phải chịu trách nhiệm
          liên đới với bất kì quyết định hoặc phán quyết nào của cơ quan có thẩm
          quyền.
        </Typography>
        <Typography>
          <strong>14.4 </strong>
          Những vấn đề chưa được quy định trong Thỏa Thuận này sẽ được Các Bên
          thống nhất áp dụng quy định Pháp luật của Nước Cộng Hòa Xã Hội Chủ
          Nghĩa Việt Nam.
        </Typography>
        <Typography>
          <strong>14.5 </strong>
          Thỏa Thuận không xác định thời hạn và chấm dứt tùy vào điều kiện xảy
          ra như: Người Dùng vi phạm chính sách của SEVI dẫn tới bị khóa vĩnh
          viễn tài khoản; Người Dùng chấm dứt Thỏa Thuận; Ứng dụng SEVI không
          còn tồn tại.
        </Typography>
      </Box>

      <Box marginTop={2}>
        <Typography>
          <i>
            TÔI ĐÃ ĐỌC CÁC ĐIỀU KHOẢN DỊCH VỤ NÀY VÀ ĐỒNG Ý VỚI TẤT CẢ CÁC ĐIỀU
            KHOẢN NHƯ TRÊN CŨNG NHƯ BẤT KỲ ĐIỀU KHOẢN NÀO ĐƯỢC CHỈNH SỬA SAU
            NÀY. BẰNG CÁCH BẤM NÚT “ĐĂNG KÝ” KHI ĐĂNG KÝ SỬ DỤNG ỨNG DỤNG SEVI,
            TÔI HIỂU RẰNG TÔI ĐANG TẠO CHỮ KÝ ĐIỆN TỬ MÀ NÓ CÓ GIÁ TRỊ VÀ HIỆU
            LỰC TƯƠNG TỰ NHƯ CHỮ KÝ TÔI KÝ BẰNG TAY.
          </i>
        </Typography>
      </Box>
    </Box>
  );
};

export default TermsPage;
