import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import AddAlertIcon from "@mui/icons-material/AddAlert";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import SecurityIcon from "@mui/icons-material/Security";
import React from "react";
export const fakeInfo = [
  {
    value: '---',
    label: "seller",
    color: "#55149B",
  },
  {
    value: 63,
    label: "city",
    color: "#4030DE",
  },
  {
    value: '---',
    label: "tradeDaily",
    color: "#BA1CDE",
  },
  {
    value: '---',
    label: "tradeSuccess",
    color: "#2D229B",
  },
];

export const SellerInfo = [
  "Có <strong>--- công việc </strong> cần tìm thợ được giao trên SEVI mỗi ngày",
  "<strong>Không giới hạn </strong> ngành nghề trên SEVI",
  "Giá cả được quyết định giữa <strong>Bạn</strong> và <strong>Khách hàng</strong>",
  "Mức <strong>Chiết khấu</strong> bằng <strong>0%</strong>",
  "Thông tin <strong>Bảo mật 100%</strong>",
];

export const BuyerInfo = [
  "Có <strong>--- thợ lành nghề</strong> trên SEVI",
  "SEVI có <strong>Tất cả</strong> các <strong>Lĩnh vực</strong> khách hàng cần",
  "Giá cả được quyết định giữa <strong>Bạn</strong> và <strong>Khách hàng</strong>",
  "Thông tin khách hàng <strong>Bảo mật 100%</strong>",
  "<strong>Cam kết rủi ro</strong> khi làm việc tại nhà",
];

export const InfoList = [
  {
    icon: <AccountBalanceWalletIcon color="inherit" />,
    title: "Tiết kiệm thời gian, công sức",
  },
  {
    icon: <AddAPhotoIcon color="inherit" />,
    title: "Hệ thống đánh giá Thợ và khách",
  },
  { icon: <AddAlertIcon color="inherit" />, title: "Sắp xếp lịch hẹn phù hợp" },
  {
    icon: <SecurityIcon color="inherit" />,
    title: "Bảo vệ thông tin Thợ và khách 100%",
  },
];
export const ListQuestion = [
  {
    question: "Tôi là thợ, tôi có được tham gia các hỗ trợ về bảo hiểm?",
    answer:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque fermentum faucibus nulla faucibus sit etiam mi. Quam phasellus placerat purus lacus id. Scelerisque cras elit ornare nunc. Amet, neque lorem quis risus purus mas ",
  },
  {
    question:
      "Tôi là thợ, nếu xảy ra tai nạn trong quá trình làm việc, SEVI có chịu một phần trách nhiệm?",
    answer: " ",
  },
  {
    question:
      "Tôi là khách, trong trường hợp xảy ra trộm cắp, tôi phải làm thế nào?",
    answer: " ",
  },
  {
    question:
      "Tôi là khách, làm thế nào để khiếu nại khi xảy ra vấn đề không mong muốn?",
    answer: " ",
  },
];
