import {
  Box,
  Button,
  ButtonBase,
  Card,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { Email, Phone } from "@mui/icons-material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import React, { useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { PRIMARY } from "../../../configs/colors";
import { ReactComponent as AppStore } from "../../../svg/appstore.svg";
import ImageBuyer from "../../../svg/buyer.jpg";
import { ReactComponent as GooglePlay } from "../../../svg/googlePlay.svg";
import BackGround from "../../../svg/image_1.jpg";
import AppIcon from "../../../svg/image_2.svg";
import Background2 from "../../../svg/image_3.jpg";
import { ReactComponent as Logo } from "../../../svg/logo.svg";
import { ReactComponent as Point } from "../../../svg/point.svg";
import ImageSeller from "../../../svg/seller.jpg";
import { BuyerInfo, fakeInfo, InfoList, SellerInfo } from "../constants";
import styled from "@emotion/styled";

const HoverGrid = styled(Grid)`
  :hover {
    background-color: #F8F3EF;
  }
`;

function DesktopHomePage() {
  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: "auto" });
  }, []);

  return (
    <Box style={{ minWidth: 1024 }}>
      <Box
        position="relative"
        style={{ backgroundColor: "white", paddingBottom: 16 }}
      >
        <img
          src={BackGround}
          alt=""
          style={{ width: "100%", height: 740, objectFit: "cover" }}
        />
        <Box
          position="absolute"
          color="white"
          top={16}
          left={150}
          right={150}
          bottom={16}
        >
          <Logo />
          <Typography
            variant="h2"
            style={{ marginTop: 100, fontWeight: 700 }}
            color="primary"
          >
            <FormattedMessage id="seviMarket" />
          </Typography>
          <Typography
            variant="h2"
            color="inherit"
            style={{ marginTop: 16, fontWeight: 700 }}
          >
            <FormattedMessage id="seviMarketTitle" />
          </Typography>
          <Typography
            variant="body1"
            style={{ marginTop: 40, width: 420 }}
            color="inherit"
          >
            <FormattedMessage id="seviMarketContent" />
          </Typography>
          <Box
            marginTop={7}
            borderRadius={8}
            //bgcolor={"rgba(0,0,0,0.2)"}
            padding={1}
            width={"320px"}
          >
            <Box>
              <Typography variant="subtitle1">
                <FormattedMessage id="forBuyer" />
              </Typography>
            </Box>
            <Box display="flex">
              <ButtonBase
                href="https://apps.apple.com/vn/app/sevi-d%E1%BB%8Bch-v%E1%BB%A5-quanh-ta/id1600928235"
                target="_blank"
                style={{ marginRight: 24 }}
              >
                <AppStore />
              </ButtonBase>
              <ButtonBase
                href="https://play.google.com/store/apps/details?id=vn.sevi.buyer"
                target="_blank"
              >
                <GooglePlay />
              </ButtonBase>
            </Box>
          </Box>
          <Box
            marginTop={7}
            borderRadius={1}
            bgcolor={"rgba(117, 64, 3, 0.2)"}
            padding={1}
            width={"310px"}
          >
            <Box>
              <Typography variant="subtitle1">
                <FormattedMessage id="forSeller" />
              </Typography>
            </Box>
            <Box display="flex">
              <ButtonBase
                href="https://apps.apple.com/vn/app/sevi-seller/id1601013753"
                target="_blank"
                style={{ marginRight: 24 }}
              >
                <AppStore />
              </ButtonBase>
              <ButtonBase
                href="https://play.google.com/store/apps/details?id=vn.sevi.seller"
                target="_blank"
              >
                <GooglePlay />
              </ButtonBase>
            </Box>
          </Box>
        </Box>
      </Box>
      <Container
        style={{ paddingTop: 112, paddingBottom: 42, textAlign: "center" }}
      >
        <Grid container spacing={4}>
          {fakeInfo.map((value, index) => {
            return (
              <Grid item xs={3} key={index}>
                <Card
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: 24,
                    borderRadius: 0,
                  }}
                  elevation={0}
                >
                  <Typography variant="h3" style={{ color: value.color }}>
                    {value.value}
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{
                      color: value.color,
                      marginTop: 12,
                      marginBottom: 24,
                    }}
                  >
                    <FormattedMessage id={value.label} />
                  </Typography>
                  <Card
                    style={{ background: "#4030DE", height: 5, width: 56 }}
                  />
                </Card>
              </Grid>
            );
          })}
        </Grid>
        <Typography variant="h4" style={{ marginTop: 60, marginBottom: 8 }}>
          <FormattedMessage id={"title1"} />
        </Typography>
        <Typography variant="h4" style={{ fontWeight: 700, marginBottom: 60 }}>
          <FormattedMessage id={"title2"} />
        </Typography>
        <img src={AppIcon} alt="" />
      </Container>
      <Box bgcolor="white">
        <Container style={{ display: "flex", padding: "80px 10px" }}>
          <img src={ImageSeller} alt="" style={{ flex: 1 }} />
          <Box marginLeft={6} flex={1}>
            <Typography variant="h4" style={{ fontWeight: 700 }}>
              <FormattedMessage id={"youAreBuyer"} />
            </Typography>
            <Typography
              variant="h5"
              style={{ marginBottom: 42, marginTop: 12 }}
            >
              <FormattedMessage id={"findBuyer"} />
            </Typography>
            <Box>
              {SellerInfo.map((v, i) => (
                <Box display="flex" key={i} marginBottom={7}>
                  <Point style={{ marginRight: 8 }} />
                  <Typography variant="h6" style={{ fontWeight: "normal" }}>
                    {
                      <div
                        dangerouslySetInnerHTML={{
                          __html: v,
                        }}
                      />
                    }
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box display="flex">
              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{ marginRight: 16 }}
                onClick={scrollToTop}
              >
                <FormattedMessage id="register" />
              </Button>
              <ButtonBase>
                <Typography variant="body1">
                  <FormattedMessage id="policy" />
                </Typography>
              </ButtonBase>
            </Box>
          </Box>
        </Container>
      </Box>
      <Box>
        <Container style={{ display: "flex", padding: "80px 10px" }}>
          <Box marginRight={6} flex={1}>
            <Typography variant="h4" style={{ fontWeight: 700 }}>
              <FormattedMessage id={"youAreSeller"} />
            </Typography>
            <Typography
              variant="h5"
              style={{ marginBottom: 42, marginTop: 12 }}
            >
              <FormattedMessage id={"findJob"} />
            </Typography>
            <Box>
              {BuyerInfo.map((v, i) => (
                <Box display="flex" key={i} marginBottom={7}>
                  <Point style={{ marginRight: 8 }} />
                  <Typography variant="h6" style={{ fontWeight: "normal" }}>
                    {
                      <div
                        dangerouslySetInnerHTML={{
                          __html: v,
                        }}
                      />
                    }
                  </Typography>
                </Box>
              ))}
            </Box>
            <Box display="flex">
              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{ marginRight: 16 }}
                onClick={scrollToTop}
              >
                <FormattedMessage id="register" />
              </Button>
              <ButtonBase>
                <Typography variant="body1">
                  <FormattedMessage id="policy" />
                </Typography>
              </ButtonBase>
            </Box>
          </Box>
          <img src={ImageBuyer} alt="" style={{ flex: 1 }} />{" "}
        </Container>
      </Box>
      <Box bgcolor="white" paddingX={2}>
        <Container
          style={{
            display: "flex",
            justifyContent: "center",
            padding: "80px 0px",
            textAlign: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h4"
            style={{
              fontWeight: 700,
              marginBottom: 60,
              textTransform: "uppercase",
              width: "50%",
            }}
          >
            <FormattedMessage id={"title3"} />
          </Typography>
          <Grid container spacing={0} alignContent="center" alignItems="center">
            {InfoList.map((v, i) => (
              <HoverGrid
                item
                xs={3}
                key={i}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "24px 32px",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    padding: 8,
                    borderRadius: 8,
                    color: "white",
                    backgroundColor: PRIMARY,
                    width: 44,
                    height: 44,
                  }}
                >
                  {v.icon}
                </div>
                <Typography
                  variant="h5"
                  style={{
                    marginTop: 12,
                  }}
                >
                  {v.title}
                </Typography>
              </HoverGrid>
            ))}
          </Grid>
        </Container>
      </Box>
      <Box
        // position="relative"
        style={{
          backgroundImage: `url("${Background2}")`,
          height: "100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "80px 100px",
            alignItems: "center",
            color: "white",
          }}
        >
          <Typography variant="h3" color="inherit">
            <FormattedMessage id={"registerPromotion"} />
          </Typography>
          <Typography
            variant="h6"
            style={{
              marginTop: 12,
              marginBottom: 24,
            }}
            color="inherit"
          >
            <FormattedMessage id={"registerPromotionContent"} />
          </Typography>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            onClick={scrollToTop}
          >
            <Typography color="primary">
              <FormattedMessage id="register" />
            </Typography>
          </Button>
        </Container>
      </Box>
      <Box paddingY={11}>
        {/* <Container>
          <Typography
            variant="h3"
            style={{ textAlign: "center", marginBottom: 62 }}
          >
            <FormattedMessage id="frequentlyQuestion" />
          </Typography>
          {ListQuestion.map((v, i) => (
            <QuestionBox data={v} key={i} />
          ))}
        </Container> */}
      </Box>
      <Box bgcolor="black" color="white" component={"footer"}>
        <Container style={{ padding: "32px 0px" }}>
          <Box display="flex" alignItems="center" paddingX={2}>
            <Box flex={1}>
              <Logo />
            </Box>
            <LocationOnIcon color="inherit" />
            <Typography
              variant="body2"
              color="inherit"
              style={{ marginLeft: 16, marginRight: 24 }}
            >
              16/17 Hoàng Ngọc Phách, Đống Đa, Hà Nội
            </Typography>
            <Email color="inherit" />
            <Typography
              variant="body2"
              color="inherit"
              style={{ marginLeft: 16, marginRight: 24 }}
            >
              contact@sevi.vn
            </Typography>
            <Phone color="inherit" />
            <Typography
              variant="body2"
              color="inherit"
              style={{ marginLeft: 16 }}
            >
              0904391987
            </Typography>
          </Box>
        </Container>
        <Divider />
        <Container style={{ padding: "16px 0px" }}>
          <Box display="flex" alignItems="center">
            <Box flex={1}>
              <Typography
                variant="caption"
                color="inherit"
                style={{ marginLeft: 16, marginRight: 24 }}
              >
                © 2021 Sevi Vietnam Technology Development LLC
              </Typography>
            </Box>
            <Typography
              variant="caption"
              color="inherit"
              style={{ marginLeft: 16, marginRight: 24 }}
            >
              <a
                href="/terms"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Điều khoản sử dụng dịch vụ và chính sách bảo mật
              </a>
            </Typography>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default DesktopHomePage;
