import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { ThemeProvider } from "@mui/material/styles";
import { ConnectedRouter } from "connected-react-router";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import { MUI_THEME } from "./configs/setupTheme";
import "./index.css";
import ConnectedIntlProvider from "./intl/component/ConnectedIntlProvider";
import { setLocale } from "./intl/redux/intlReducer";
import configureStore, { history } from "./redux/configureStore";
import reportWebVitals from "./reportWebVitals";

export const { store } = configureStore({});

store.dispatch(setLocale("vi-VN"));

const uas = ["Prerender"];
const prerender =
  typeof navigator !== "undefined" &&
  uas.findIndex((one) => {
    return navigator.userAgent.indexOf(one) > -1;
  }) > -1;

if (prerender) {
  const cache = createCache({
    key: "sevi-css",
    speedy: false,
  });

  ReactDOM.render(
    <React.StrictMode>
      <CacheProvider value={cache}>
        <ThemeProvider theme={MUI_THEME}>
          <Provider store={store}>
            <ConnectedRouter history={history}>
              <ConnectedIntlProvider>
                <App />
              </ConnectedIntlProvider>
            </ConnectedRouter>
          </Provider>
        </ThemeProvider>
      </CacheProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
} else {
  ReactDOM.render(
    <React.StrictMode>
      <ThemeProvider theme={MUI_THEME}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <ConnectedIntlProvider>
              <App />
            </ConnectedIntlProvider>
          </ConnectedRouter>
        </Provider>
      </ThemeProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
}
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
