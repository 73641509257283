import { Box, List, ListItem, Paper, Stack, Typography } from "@mui/material";
import useSWR from "swr";
import { API_PATHS } from "../../../configs/api";

export default function StatsPage() {
  const { data, isValidating } = useSWR(
    API_PATHS.operatingStats,
    async (url) => {
      const res = await fetch(url);
      if (res.ok) {
        return await res.json();
      }
      throw new Error(res.statusText);
    }
  );

  if (isValidating || !data) {
    return (
      <Paper>
        <Stack padding={3}>
          <Box display={"flex"} justifyContent="center">
            <Typography>Đang tải</Typography>
          </Box>
        </Stack>
      </Paper>
    );
  }

  return (
    <Paper>
      <Stack padding={3}>
        <Box display={"flex"} justifyContent="center">
          <Typography variant="h2">Báo cáo tình hình hoạt động</Typography>
        </Box>
        <Box>
          <List>
            <ListItem>Số lượng truy cập: {data.SoLuongTruyCap}</ListItem>
            <ListItem>
              <List>
                <Box>Số người bán:</Box>
                <ListItem>Tổng số trên hệ thống: {data.SoNguoiBan}</ListItem>
                <ListItem>
                  Số lượng người bán mới: {data.SoNguoiBanMoi}
                </ListItem>
              </List>
            </ListItem>
            <ListItem>
              <List>
                <Box>Số lượng sản phẩm:</Box>
                <ListItem>
                  Tổng số sản phẩm (SKU): {data.TongSoSanPham}
                </ListItem>
                <ListItem>Số sản phẩm mới: {data.SoSanPhamMoi}</ListItem>
              </List>
            </ListItem>
            <ListItem>
              <List>
                <Box>Số lượng giao dịch:</Box>
                <ListItem>
                  Tổng số đơn hàng:{" "}
                  {data.TongSoDonHangThanhCong +
                    data.TongSoDonHangKhongThanhCong}
                </ListItem>
                <ListItem>
                  Tổng số đơn hàng thành công: {data.TongSoDonHangThanhCong}
                </ListItem>
                <ListItem>
                  Tổng số đơn hàng không thành công:{" "}
                  {data.TongSoDonHangKhongThanhCong}
                </ListItem>
              </List>
            </ListItem>
            <ListItem>Số giá trị giao dịch (Khuyến cáo: Nền tảng Sevi không theo dõi và quản lý giá trị của mỗi giao dịch, đây là thỏa thuận riêng của người dùng Sevi với nhau) : {data.TongGiaTriGiaoDich}</ListItem>
          </List>
        </Box>
      </Stack>
    </Paper>
  );
}
